import React from 'react'
import { includes, pathOr } from 'ramda'
import { graphql } from 'gatsby'
import { withAuthProvider } from '../../../lib/auth'
import { Query, Mutation } from 'react-apollo'
import Layout from '../../../layouts/base-with-sidebar-for-pages'
import { SUBSCRIPTION_QUERY, REGISTER_MUTATION } from '../../../queries'
import { iconCards } from '../../../../../_data/specialization-registration'
import IconCards from '../../../components/IconCards'

const SPP_LEVELS = ['M1']

const isExpired = ({ endDate } = {}) => new Date () > new Date(endDate)
const findSpp = (list, idField = 'productId') => list.find(s => SPP_LEVELS.includes(s[idField])) || {}
const hasSppSubscription = (subs) => Object.keys(findSpp(subs)).length > 0
const hasSppExpired = (subs) => isExpired(findSpp(subs))
const hasSppLevelComplete = (levels) => {
  const { endDate, completeCd } = findSpp(levels, 'levelCd')
  return completeCd === 'COMPLETE' || !!endDate
}

const SPECIALIZED_URL = 'https://apps.pga.org/professionals/apps/education/specialized'
const getSppUrl = id => `${SPECIALIZED_URL}/checkout.cfm?entity_id=${id}`

export const RegisterLink = ({ label, url }) => (
  <a className='btn btn-primary btn-lg-block btn-enroll text-white' href={url}>
    {label}
  </a>
)

export const DisabledButton = () => <button className='btn btn-primary btn-lg-block btn-enroll text-white' disabled>Enroll Now</button>

export const SppEnrollOrRenew = ({ data }) => {
  const subscriptions = pathOr([], ['me', 'education', 'subscriptions'], data)
  const levels = pathOr([], ['me', 'education', 'levels'], data)
  const meId = pathOr(null, ['me', 'id'], data)

  // if level is not complete and the subscription is expired,
  // then they should be allowed to renew
  if (hasSppSubscription(subscriptions)) {
    if (hasSppExpired(subscriptions) && !hasSppLevelComplete(levels)) {
      const url = getSppUrl(meId)
      return <RegisterLink label='Renew Now' url={url} />
    }
    return <DisabledButton />
  }

  // No subscriptions - Enroll M1
  return <RegisterLink label='Enroll Now' url={getSppUrl(meId)} />
}

export const SubscriptionQuery = ({ me }) => {
  return (
    <Query query={SUBSCRIPTION_QUERY}>
      {({ loading, error, data }) => {
        if (loading) return <p>Loading...</p>
        if (error) return <p>There was an error with the request.</p>
        return <SppEnrollOrRenew data={data} me={me} />
      }}
    </Query>
  )
}

export const PurchaseButton = ({ me = {} }) => {
  const allowRegistrationFor = ['MB']
  const allowPurchaseFor = type => includes(type, allowRegistrationFor)

  return (
    allowPurchaseFor(me.type)
      ? <SubscriptionQuery {...{ me }} />
      : <DisabledButton />
  )
}

export const PageWrapper = (props) => {
  return (
    <Layout {...props} >
      <div className='row'>
        <div className='col-lg-6'>
          <p>Continuously improve your business and teaching skills to meet the increasing demands of the golf industry. The PGA Specialized Program is an online educational curriculum that supports the goals of PGA of America Members who are committed to excellence and furthering their career. It delivers "on-demand" access to training when your schedule permits, and reduces time and travel costs associated with traditional training. </p>
        </div>
        <div className='col-lg-6'>
          <div className='card p-4'>
            <div>
              <div className='badge badge-primary'>PGA of America Members</div>
            </div>
            <h3 className='my-2'>PGA Specialized Program</h3>
            <p>Grow your career. Become a Specialized Professional.</p>
            <h4 className='my-2'>$350 / Online Course Access</h4>
            <p className='text-info'><a href='/lifelong-learning/specialized-professional-program/project-criteria-evaluation-rubric/'>View Program Criteria</a></p>
            <PurchaseButton me={props.me} />
          </div>
        </div>
      </div>
      <h3>The PGA Specialized Program</h3>
      <IconCards data={iconCards} />
      <h4>To complete the Specialized designation, you must:</h4>
      <ul className='ul-bullets-primary fw-500'>
        <li>Be a PGA of America Member</li>
        <li>Pay a registration fee of $350. Course library offers more than 120 courses (PDR Eligible) and registration is active for one (1) year</li>
        <li>Select one of three specific career paths: Teaching &amp; Coaching, Golf Operations or Executive Management</li>
        <li>Complete the 40 Required courses within your career path selection</li>
        <li>Submit the Advising documentation (Advise 3 PGA of America Golf Professionals)</li>
        <li>Submit a Capstone project (View the project guidelines <a href='/lifelong-learning/specialized-professional-program/project-criteria-evaluation-rubric/'>here</a>).</li>
      </ul>
      <h5>Once Specialization is complete, the next step in your journey is Certification.</h5>
    </Layout>
  )
}

export const query = graphql`
  query {
    allMarkdownRemark(
      filter: {
        fields: {
          slug: {
            regex: "/specialized-professional-program/"
          }
        }
        fileAbsolutePath: {
          regex: "/index/"
        }
      }
    ) {
      edges {
        node {
          fileAbsolutePath
          fields {
            slug
            category
            subCategory
            article
          }
          frontmatter {
            title
            header_image
            sidebar_label
            order_number
          }
        }
      }
    }
    categoryMarkdowns: allMarkdownRemark(
      filter: {
        fields: {
          slug: {
            regex: "/lifelong-learning/"
          }
        }
      }
    ) {
      edges {
        node {
          ...MarkdownFrontmatter
        }
      }
    }
  }
`

export default withAuthProvider(PageWrapper)
